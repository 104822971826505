@import 'npm:bootstrap/scss/bootstrap';
@import 'npm:bootstrap-icons/font/bootstrap-icons.css';

.formj-suggestion {
    font-family: var(--bs-font-monospace);

    display: inline-block;
    vertical-align: bottom;

    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subheader {
    @extend .text-muted;
    @extend .text-uppercase;
    @extend .fw-bold;

    letter-spacing: 0.04em;
    font-size: 0.8rem;
    line-height: 1rem;
}
